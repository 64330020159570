///// Color codes and font family
$theme_font_family: 'Lato-Regular';
$theme_color: #FEE000;
$black_color: hwb(0 0% 100%);
$white_bg: #FFFFFF;
$content_bg: #F5F5F5;
$selected_plant: #E77A1D;
$selected_line: #4972C4;
$dark_gray: #A9A9A9;
$base_color: #E7E7E7;
$button_stroke: #E3C942;
$label_color: #323130;
$env_bgcolor: #f0f0f0;
$env_label_color: #979797;
$light_Grey: #F3F3F6;
$dark_Grey: #434043;
$color_gainsboro: #DCDCDC;
$nav-tab-border: #e1e3e1;
$box_border: #adadad;
$zero_report_bg_color: #828288;
$disable_bg_color: #e9ecef;
$disable_border_color: #bcbcbc;
$selected_item_bg: #E7E7E7;
$disable_pagination_arrow: #C4CDD5;
$warning_bg: #fde7e9;
$dark_blue: #120563;
//red accordian
$color-red: #e03100;
$color-warning-red:#a80000;
$color-light-red: #fceae5;
//blue accordian
$color-blue: #075688;
$color-light-blue: #e6eef3;
//yellow accordian
$color-yellow: #ffd147;
$color-light-yellow: #fff6da;
//green accordian
$color-green: #008f5d;
$color-light-green: #e5f4ee;
//purple accordian
$color-purple: #6a66a3;
$color-light-purple: #f0eff6;
//skyblue accordian
$color-skyblue: #86bbd8;
$color-light-skyblue: #f3f8fb;
$color_violet: #8A1CCE;
$color-light-blue:#bacae2;

// Huddle Board Color codes
$safety_color: #E03100;
$quality_color: #075688;
$delivery_color: #FFD147;
$people-color: #6a66a3;
$cost-color: #008f5d;
$environment-color: #86bbd8;
$light_Blue: #5D89E9;
$safety-data-bg: #E031001A;
$quality-data-bg: #0756881a;
$cost-data-bg: #008f5d1a;
$people-data-bg: #6a66a31a;
$delivery-data-bg: #ffd1471a;
$env-data-bg: #86bbd81a;
$border-color: #C3C3C7;
$in-progres-bg: #EA7603;
$border-bottom-color: #828288;
$tick-color: #008f5d;
$delivery-packaging-title-bg: #fbe297;
$cost-data-packaging-bg: #a7d5c5;
$env-title-sub-bg: #bfdff1;
$huddle-board-btn-border: #9f9696;
$action-log-defect-background: #f3f3f5;
$action-log-defect-popup-background:#c2c2c6;
$row-hover-color:#efefef;
$infoicon_item_bg:#EFF0F1;
$hyper_link_color:#0d6efd;
///// Font sizes & weights
$font-size-32: 2rem;
$font_size_24: 1.5rem;
$font_size_18: 1.125rem;
$font_size_20: 1.25rem;
$font_size_16: 1rem;
$font_size_14: 0.875rem;
$font_size_13: 0.813rem;
$font_size_12: 0.75rem;
$font_size_10: 0.625rem;
$font_size_5: 0.313rem;
$font_size_40: 2.5rem;
$font_size_60: 3.75rem;
$font_size_64: 4rem;
$font_size_80: 5rem;
$font-size-100: 6.25rem;
$font_size_28: 1.75rem;
$font_size_200: 12.5rem;
$font_weight_400: 400;
$font_weight_semibold: 500;
$font_weight_bold: 600;
$font_weight_400: 400;
$font_weight_700: 700;
$font_weight_800: 800;
$font_weight_900: 900;
$text-uppercase: uppercase;

//style for loader 
$color-loader: #6a66a3;
$background-overlay: #e1e1e3b3;