/* You can add global styles to this file, and also import other style files */
@import "../src/assets/styles/variables";
@import "../src/assets/styles/mixins";
@import "../src/assets/styles/multiselect_dropdown";
@import "@danielmoncada/angular-datetime-picker/assets/style/picker.min.css";

@font-face {
  font-family: "Montserrat-Regular";
  src: url(assets/fonts/Montserrat/Montserrat-Regular.ttf);
}

@font-face {
  font-family: "Lato-Regular";
  src: url(assets/fonts/Lato/Lato-Regular.ttf);
}

body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: $theme_font_family;
}

.overlay_main {
  display: none !important;
}

.overlay-logout-class {
  .overlay_main {
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    position: fixed;
    background: rgba(0, 0, 0, 0.4);
    z-index: 999;
    display: block !important;

    .overlay_main__content {
      text-align: center;
      margin-top: 22%;

      p {
        text-transform: uppercase;
        color: $color-blue;
      }
    }

    .spinner_main {
      width: 50px;
      height: 50px;
      display: inline-block;
      border-width: 2px;
      border-color: rgba(255, 255, 255, 0.3);
      border-top-color: $color-blue;
      animation: spin 1s infinite linear;
      border-radius: 100%;
      border-style: solid;
    }
  }
}

input[type^="datetime-local"]:focus {
  box-shadow: none !important;
  border-color: $black_color !important;
  outline: none !important;
}

input[type^="time"]:focus {
  box-shadow: none !important;
  border-color: $black_color !important;
  outline: none !important;
}

input[type^="text"] {
  box-shadow: none !important;
  border-color: $box_border !important;
  outline: none !important;
}

p,
span {
  font-family: $theme_font_family;
}

.modal-dialog-centered {
  .modal-content {
    border-radius: 0px !important;

  }
}

// button {
//     background-color: $theme_color;
//     border-color: $button_stroke;
// }

//Alignment of asterisk symbol on the accordion
.accord-container {
  .bi-asterisk {
    font-size: 0.5rem;
    vertical-align: 0.77em;
    margin-right: 0.2rem;
  }
}

.accordion-header {
  margin-bottom: 0;
}

input[type="datetime-local"]::-webkit-calendar-picker-indicator {
  background-image: url("../src/assets/images/calendar.svg");
  background-size: 1rem;
  background-repeat: no-repeat;
}

input[type="time"]::-webkit-calendar-picker-indicator {
  background-image: url("../src/assets/images/clock-history.svg");
  background-size: 1rem;
  background-repeat: no-repeat;
}

.accord-container {
  .information-accord-container {
    .multiselect-dropdown .dropdown-btn {
      display: flex !important;
      width: 11.5rem !important;
      height: 3rem !important;
      padding: 0.6rem 1rem !important;

      .dropdown-multiselect__caret::before {
        height: 1.3rem;
        top: 0.4rem !important;
        margin-top: 0rem !important;
      }
    }

    .information-textdata {
      display: flex !important;
      width: 11.5rem !important;
      height: 3rem !important;
    }

    .dropdown-list {
      li {
        height: 2.5rem;
      }
    }
  }
}

// Common classes for padding and background colors
.safety-title-bg {
  background-color: $safety_color;
}

.safety-data-bg {
  background-color: $safety-data-bg;
  word-break: break-word;
}

.quality-title-bg {
  background-color: $quality_color;
}

.quality-data-bg {
  background-color: $quality-data-bg;
  word-break: break-word;
}

.delivery-title-bg {
  background-color: $delivery_color;
  color: $black_color !important;
}

.delivery-data-bg {
  background-color: $delivery-data-bg;
  color: $black_color !important;
  word-break: break-word;
}

.cost-data-bur-bg {
  background-color: $cost-data-bg;
  color: $black_color !important;
  word-break: break-word;
}

.delivery-packaging-title-bg {
  background-color: $delivery-packaging-title-bg;
  color: $black_color !important;
  font-weight: $font_weight_700;
}

.cost-title-bg {
  background-color: $cost-color;
}

.cost-title-bur-bg {
  background-color: $cost-color;
  color: $black_color !important;
}

.cost-data-bg {
  background-color: $cost-data-bg;
}

.cost-data-packaging-bg {
  background-color: $cost-data-packaging-bg;
}

.people-title-bg {
  background-color: $people-color;
}

.people-data-bg {
  background-color: $people-data-bg;
  word-break: break-word;
}

.env-title-bg {
  background-color: $environment-color;
  color: $black_color !important;
}

.env-data-bg {
  background-color: $env-data-bg;
  word-break: break-word;
}

.env-title-sub-bg {
  background-color: $env-title-sub-bg;
}

.flex-1 {
  flex: 1;
}

.spacing-p-20 {
  padding: 20px;
}

.spacing-mb-20 {
  margin-bottom: 20px;
}

.spacing-p-40 {
  padding: 40px;
}

.spacing-mr-20 {
  margin-right: 20px;
}

.spacing-mt-20 {
  margin-top: 20px;
}

.spacing-mr-0 {
  margin-right: 0;
}

.spacing-pl-0 {
  padding-left: 0;
}

.spacing-pt-20 {
  padding-top: 20px;
}

.two-line-spacing {
  padding-top: 39px;
}

.spacing-pt-10 {
  padding-top: 10px;
}

.spacing-pt-17 {
  padding-top: 17px;
}

.width-100 {
  width: 100%;
}

.height-100 {
  height: 100%;
}

.text-uppercase {
  text-transform: uppercase;
}

.text-lowercase {
  text-transform: lowercase;
}

.text-capitalize {
  text-transform: capitalize;
}

.font-bold {
  font-weight: $font_weight_bold;
}

/**** Previous Handoff form UI Start ****/
.handoff-form .dropdown-line .multiselect-dropdown span {
  scrollbar-width: none !important;
  // font-size: 0.7rem !important;
  min-height: 3rem !important;
  max-height: 3rem !important;
  overflow: hidden !important;
}

.handoff-container {
  &.category-container {
    margin-bottom: 20px;

    .comments-section {
      .response-sec {
        p {
          font-size: $font_size_14;
          color: $black_color;
          overflow: auto;
          //width: 80%;
          scrollbar-width: none;
          white-space: nowrap;
        }
      }
    }
  }

  .textbox-wrapper {
    overflow: auto;
    //width: 80%;
    scrollbar-width: none;
    white-space: nowrap;
  }

  .response-sec {
    p {
      font-size: $font_size_14;
      color: $black_color;
      overflow: auto;
      //width: 80%;
      scrollbar-width: none;
      white-space: nowrap;
    }
  }

  h3 {
    font-size: $font_size_16;
    font-weight: $font_weight_semibold;
    color: $black_color;
  }

  .label {
    font-size: $font_size_14;
    font-weight: $font_weight_400;
    color: $dark_Grey;
    padding-bottom: 8px;
  }

  .items-section {
    .response-sec {
      border: 1px solid $border-color;
      height: 40px;
      line-height: 40px;
      padding-left: 8px;

      // padding: 12px;
      p {
        font-size: $font_size_14;
        color: $black_color;
      }
    }
  }

  .comments-section {

    // .spacing-pt-20 {
    //     padding-top:16px;
    // }
    .response-sec {
      border: 1px solid $border-color;
      height: 40px;
      line-height: 40px;
      padding-left: 8px;
      margin-top: 11px;

      p {
        font-size: $font_size_14;
        color: $black_color;
        overflow: auto;
        //width: 80%;
        scrollbar-width: none;
        white-space: nowrap;
      }
    }
  }

  .safety-header {
    font-weight: bolder;

    &.report-header {
      font-size: $font_size_14;
    }
  }

  .custom-radio-button {
    @include custom-radio-button;
  }

  .textbox-content {
    height: 4rem;
    @include textbox-content;
  }

  .textbox-wrapper {
    @include textbox-wrapper;

    div {
      overflow: auto;
      //width: 80%;
      scrollbar-width: none;
      white-space: nowrap;
    }

    // div::-webkit-scrollbar-track {
    //   border: 1px solid #f1f1f1;
    //   padding: 2px 0;
    //   background-color: #f1f1f1;
    // }

    // div::-webkit-scrollbar {
    //   width: 5px !important;
    // }

    // div::-webkit-scrollbar-thumb {
    //   border-radius: 10px !important;
    //   box-shadow: inset 0 0 6px #c0c0c0;
    //   background-color: #c0c0c0;
    //   border: 1px solid #c0c0c0;
    //   width: 5px;
    // }
  }

  .zero-report-wrapper {
    @include zero-report-wrapper;
  }

  .text-report-click-url {
    text-underline-offset: 0.5rem;
  }
}

.unit-bg {
  background-color: $content-bg;
  height: 30px;
  text-align: center;
  width: auto;
  line-height: 30px;
  margin-top: 5px;
  padding-left: 5px;
  padding-right: 5px;
}

/**** Previous Handoff form UI End ****/
.comment-width {
  width: 10%;
}

/**** Current Handoff UI Start ****/
.handoff-form {

  label,
  .question-font {
    font-size: $font_size_14;
  }

  input+label {
    font-size: $font_size_14;
  }

  .title-container {
    &:first-child {
      margin-bottom: 20px;
    }
  }

  .form-control {
    font-size: $font_size_14;
  }

  .multiselect-dropdown {
    height: 40px;

    span {
      line-height: 30px;
      font-size: $font_size_14;
    }
  }

  .custom-class1 {
    .multiselect-dropdown {
      height: 40px;

      span {
        line-height: 15px;
        font-size: $font_size_14;
      }

      .multiselect-item-checkbox input[type="checkbox"]+div {
        font-size: 14px;
      }
    }
  }
}

// .h-75 {
//     height: 40px !important;
// }
/***** Current handoff UI End ****/

/***** Spinner UI *****/

.overlay {
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  position: fixed;
  background: rgba(0, 0, 0, 0.4);
  z-index: 999;

  .overlay__content {
    text-align: center;

    p {
      text-transform: uppercase;
      color: $color-blue;
    }
  }

  .spinner {
    width: 50px;
    height: 50px;
    display: inline-block;
    border-width: 2px;
    border-color: rgba(255, 255, 255, 0.3);
    border-top-color: $color-blue;
    animation: spin 1s infinite linear;
    border-radius: 100%;
    border-style: solid;
  }
}

@keyframes spin {
  100% {
    transform: rotate(360deg);
  }
}

/****** Spinner Ui End ****/
@include for-small-screen {
  .category-details-container {
    margin-bottom: $font-size-100;

    .title-section {
      width: 25rem;
      height: auto;
      font-size: $font-size-100;
      font-weight: $font_weight_700;
      color: $white_bg;
      margin-right: $font_size_20;
    }

    .cost-title-section {
      width: 21.25rem;
      height: 12.5rem;
      font-size: $font-size-100;
      font-weight: $font_weight_700;
      color: $black_color;
      margin-right: $font_size_20;
      margin-bottom: $font_size_20;

      &.col-5 {
        flex: 1;

        &:last-child {
          margin-right: 0;
          margin-bottom: 0;
        }
      }
    }

    .env-title-section {
      width: 21.25rem;
      height: 12.5rem;
      font-weight: $font_weight_700;
      color: $black_color;
      margin-right: $font_size_20;
      margin-bottom: $font_size_20;

      h3 {
        font-weight: $font_weight_700;
      }

      &.col-10 {
        flex: 1;
      }
    }

    .safety-content-section {
      flex: 1;
      height: 100%;

      .category-name {
        color: $white_bg;
        height: 12.5rem;
        margin-bottom: $font_size_20;

        h3 {
          font-size: $font-size-100;
          font-weight: $font_weight_700;
          text-transform: $text-uppercase;
        }
      }

      .category-title-section {
        &.people-title-section {
          .items-section {
            // width: 50%;
          }

          .comments-section {
            flex: 1;
          }
        }

        .items-section {
          color: $white_bg;
          width: 75rem;
          height: 12.5rem;
          padding: 0 0px 0 $font_size_40;
        }

        .value-section {
          color: $white_bg;
          height: 12.5rem;
          margin: 0 $font_size_20 0 $font_size_20;
          padding: 0 0 0 $font_size_40;
          flex: 1;
        }

        .comments-section {
          color: $white_bg;
          height: 12.5rem;
          padding: 0 0px 0 $font_size_40;
          margin: 0;
          flex: 1;
          overflow: auto;
          //width: 80%;
          scrollbar-width: none;
          white-space: nowrap;
        }

        h3 {
          font-size: $font-size-100;
          font-weight: $font_weight_700;
          text-transform: $text-uppercase;
        }
      }

      // Category details section
      .category-details-section {
        &.people-details-section {
          h3 {
            font-size: $font_size_80;
            font-weight: $font_weight_700;
          }

          p {
            font-size: $font_size_80;
            font-weight: $font_weight_400;
          }

          .people-items-section {
            // width: 50%;
          }

          .people-comments-section {
            flex: 1;
            overflow: auto;
            //width: 80%;
            scrollbar-width: none;
            white-space: nowrap;
          }
        }

        .env-details-section {
          width: 21.25rem;
          height: 12.5rem;
          font-size: $font_size_80;
          color: $black_color;
          margin-right: $font_size_20;
          margin-bottom: $font_size_20;

          h3 {
            font-size: $font_size_80;
          }

          &.col-10 {
            flex: 1;
          }
        }

        .defect-question-section {
          width: 75rem;
          height: auto;
          margin: $font_size_20 0 0px 0px;
          padding: $font_size_40;

          h4 {
            font-size: $font_size_80;
            font-weight: $font_weight_700;
          }

          p {
            font-size: $font_size_80;
            font-weight: $font_weight_400;
          }
        }

        &.environment-section {
          .defect-answer-section {
            flex: 1;
          }
        }

        .defect-answer-section {
          flex: 1;
          margin: $font_size_20 0 0px $font_size_20;

          &.delivery-answer-sec {
            h3 {
              font-size: 64px;
            }
          }

          &.delivery-sub-sec {
            h3 {
              font-weight: $font_weight_700;
              font-size: 0.813rem;
            }
          }

          &.safety-defect-answer-sec {

            h3,
            p {
              font-size: 4rem;
              padding-left: $font_size_40;
            }

            .reports {
              background-color: gray;
              padding: 5px 10px 5px 10px;
              color: white;
              margin-top: 5px;
              margin-bottom: 5px !important;
            }
          }

          &.quality-defect-section {
            flex: 1;
          }

          .user-data-row {
            height: 100%;
          }

          .data-container {
            border: 2px solid $zero_report_bg_color;
            margin-bottom: $font_size_20;

            h3 {
              font-size: 4rem;
              text-transform: $text-uppercase;
              border-bottom: 2px solid $zero_report_bg_color;
              padding-left: $font_size_40;
              font-weight: $font_weight_400;
            }

            p {
              font-size: 4rem;
              padding: $font_size_40;
              font-weight: $font_weight_400;
            }

            &:last-child {
              margin-bottom: 0;
            }
          }

          .user-data-container {
            height: 100%;
            margin-right: $font_size_20;
            width: 50%;
          }

          .user-comments-container {
            height: 100%;
            width: calc(50% - 20px);
            overflow: auto;
            //width: 80%;
            scrollbar-width: none;
            white-space: nowrap;
          }

          .option-data {
            h3 {
              text-transform: $text-uppercase;
              font-size: $font_size_80;
              margin-bottom: 0;
              padding-left: $font_size_40;
            }
          }

          .option-comments {
            width: 100%;

            h3 {
              font-size: $font_size_80;
              text-overflow: ellipsis;
              overflow: hidden;
              white-space: nowrap;
              padding-left: $font_size_40;
            }
          }

          &.no-report {
            flex: 1;
          }
        }

        .defect-status {
          width: 3.75rem;
          margin: $font_size_20 0 0 $font_size_20;
          height: auto;
          background-color: $black_color;
        }
      }
    }
  }
}

/***** KPI View higher resolution Start *****/
@include for-big-tv-screen {
  .category-details-container {
    margin-bottom: 3.25rem;

    &.cost-data-c-title {
      margin-bottom: 1rem !important;
    }

    .title-section {
      // width: 25rem;
      // height: auto;
      // font-size: $font-size-100;
      // font-weight: $font_weight_700;
      color: $white_bg;
      margin-right: $font_size_20;
      width: 12rem;
      font-size: 4rem;
      font-weight: 700;
    }

    .cost-c-title {
      margin-bottom: 2rem !important;
    }

    .cost-title-section {
      // width: 21.25rem;
      // height: 12.5rem;
      font-size: 2rem;
      // font-weight: $font_weight_700;
      color: $black_color;
      margin-right: $font_size_20;
      margin-bottom: $font_size_20;
      padding: 15px !important;

      h3 {
        overflow-wrap: anywhere;
        font-size: 1.6rem !important;
      }

      &.col-5 {
        flex: 1;

        &:last-child {
          margin-right: 0;
          margin-bottom: $font_size_20;
        }
      }
    }

    .env-title-section {
      // width: 21.25rem;
      // height: 12.5rem;
      // font-weight: $font_weight_700;
      color: $black_color;
      margin-right: $font_size_20;
      margin-bottom: $font_size_20;
      font-size: 4rem;
      font-weight: 700;

      h3 {
        // font-weight: $font_weight_700;
      }

      &.col-10 {
        flex: 1;
      }
      &.env-big-screen-margin
        {
           margin-right: 0rem !important;
        }
    }

    .env-target-section {
      width: 17% !important;
    }

    .safety-content-section {
      flex: 1;
      height: 100%;

      .env-title-bg {
        h3 {
          margin-bottom: 16px !important;
        }
      }

      .category-name {
        color: $white_bg;
        margin-top: 0px !important;
        // height: 12.5rem;
        margin-bottom: $font_size_20;

        h3 {
          // font-size: $font-size-100;
          // font-weight: $font_weight_700;
          margin-top: 2rem;
          margin-bottom: 2rem;
          text-transform: $text-uppercase;
          font-size: 3rem;
          font-weight: 700;
        }
      }

      .category-title-section {
        .delivery-section {
          height: 4rem;

          &.delivery-sub-header-div {
            flex: 1;
            margin: 1.25rem 0 0px 1.25rem;
          }

          &.delivery-sub-header-first-div {
            margin-left: 0rem !important;
          }

          h3 {
            margin-top: 12px;
          }
        }

        &.cost-section {
          margin-bottom: 0px !important;
        }

        .cost-package-tv {
          width: 14% !important;
          padding-left: 20px;

          h3 {
            font-size: 1.6rem !important;
            line-height: 2rem !important;
            margin-bottom: 0px !important;
            margin-top: 0px !important;
          }
        }

        .cost-sub-header-tv {
          width: 14% !important;
          padding-left: 20px;
        }

        &.people-title-section {
          .items-section {

            // width: 50%;
            h3 {
              margin-bottom: 16px !important;
            }
          }

          .comments-section {
            flex: 1;

            h3 {
              margin-bottom: 16px !important;
            }
          }
        }

        .delivery-title-bg {
          .delivery-header-d-top {
            margin-top: 22px;
            font-size: 2.5rem;
          }

          h3 {
            margin-bottom: 14px !important;
          }

          &.comments-section {
            padding: 0 0px 0 1.5rem !important;
          }
        }

        .items-section {
          color: $white_bg;
          // width: 75rem;
          // height: 12.5rem;
          padding: 0 0px 0 $font_size_40;
        }

        .value-section {
          color: $white_bg;
          // height: 12.5rem;
          margin: 0 $font_size_20 0 $font_size_20;
          padding: 0 0 0 $font_size_40;
          flex: 1;
        }
        .environment-padding-header {
          padding-right: 16px;
        }
        .people-padding-header{
          margin-right: 24px !important;
        }
        .comments-section {
          color: $white_bg;
          // height: 12.5rem;
          padding: 0 0px 0 $font_size_40;
          margin: 0;
          flex: 1;
          overflow: auto;
          //width: 80%;
          scrollbar-width: none;
          white-space: nowrap;
        }

        h3 {
          // font-size: $font-size-100;
          // font-weight: $font_weight_700;
          text-transform: $text-uppercase;
          margin-top: 16px;
          font-size: 2rem;
          font-weight: 700;
        }
      }

      // Category details section
      .category-details-section {
        .delivery-pack-sub-header {
          h3 {
            font-size: 1rem !important;
          }
        }

        &.environment-title-sec {
          font-size: 2rem;
          line-height: 3rem;
        }

        &.people-details-section {
          h3 {
            // font-size: $font_size_80;
            // font-weight: $font_weight_700;
            font-size: 2rem;
            line-height: 3rem;
          }

          p {
            // font-size: $font_size_80;
            // font-weight: $font_weight_400;
            font-size: 2rem;
            line-height: 3rem;
          }

          .people-items-section {
            // width: 50%;
            margin: 1.25rem 0 0px 0px;
            padding: 1.5rem;
          }

          .people-comments-section {
            flex: 1;
            margin: 1.25rem 0 0px 1.25rem;
            padding: 1.5rem;
          }
        }
        
        .env-details-section {
          // width: 21.25rem;
          // height: 12.5rem;
          // font-size: $font_size_80;
          color: $black_color;
          margin-right: $font_size_20;
          margin-bottom: $font_size_20;

          h3 {
            // font-size: $font_size_80;
          }

          &.col-10 {
            flex: 1;
          }
          &.env-big-screen-margin
          {
             margin-right: 0rem !important;
          }
        }

        .defect-question-section {
          // width: 75rem;
          // height: auto;
          margin: $font_size_20 0 0px 0px;
          padding: 1.5rem;

          h4 {
            font-size: 2rem;
            line-height: 3rem;
            // font-size: $font_size_80;
            // font-weight: $font_weight_700;
          }

          p {
            // font-size: $font_size_80;
            // font-weight: $font_weight_400;
          }
        }

        &.environment-section {
          .defect-answer-section {
            flex: 1;
          }
        }

        .defect-answer-section {
          flex: 1;
          margin: $font_size_20 0 0px $font_size_20;
          font-size: 2rem;

          &.delivery-answer-sec {
            h3 {
              font-size: 2rem;
              line-height: 3rem;
              overflow-wrap: anywhere;
            }
          }
          &.delivery-comment-ml{
            padding-left: 5px !important;
          }

          &.delivery-sub-sec {
            h3 {
              // font-weight: $font_weight_700;
              font-size: 1.5rem !important;
            }
          }

          &.delivery-padding-subheader {
            padding: 14px;
          }

          &.safety-defect-answer-sec {

            h3,
            p {
              // font-size: 4rem;
              padding-left: 12px;
              font-size: 2rem;
              line-height: 3rem;
            }

            .reports {
              background-color: gray;
              padding: 5px 10px 5px 10px;
              color: white;
              margin-top: 5px;
              margin-bottom: 5px !important;
            }
          }

          &.quality-defect-section {
            flex: 1;
          }

          .user-data-row {
            height: 100%;
          }

          .data-container {
            border: 2px solid $zero_report_bg_color;
            margin-bottom: $font_size_20;

            h3 {
              // font-size: 4rem;
              text-transform: $text-uppercase;
              border-bottom: 2px solid $zero_report_bg_color;
              padding-left: $font_size_40;
              // font-weight: $font_weight_400;
            }

            p {
              // font-size: 4rem;
              padding: $font_size_40;
              // font-weight: $font_weight_400;
            }

            &:last-child {
              margin-bottom: 0;
            }
          }

          .user-data-container {
            height: 100%;
            margin-right: $font_size_20;
            // width: 50%;
          }

          .user-comments-container {
            height: 100%;
            width: calc(50% - 20px);
            overflow: auto;
            //width: 80%;
            scrollbar-width: none;
            white-space: nowrap;
          }

          .option-data {
            h3 {
              text-transform: $text-uppercase;
              // font-size: $font_size_80;
              margin-bottom: 0;
              padding-left: $font_size_40;
            }
          }

          .option-comments {
            width: 100%;

            h3 {
              // font-size: $font_size_80;
              text-overflow: ellipsis;
              overflow: hidden;
              white-space: nowrap;
              padding-left: $font_size_40;
            }
          }

          &.no-report {
            flex: 1;
          }
        }

        .defect-status {
          // width: 3.75rem;
          margin: $font_size_20 0 0 $font_size_20;
          // height: auto;
          background-color: $black_color;
        }
      }
    }
  }
}

/***** KPI View higher resolution End *****/

/****** KPI View Desktop and mobile view *****/
@include for-mobile-to-desktop-screen {
  .kpi-view-container {
    min-width: 68.75rem;
    overflow-x: scroll;
    margin-top: 3.4rem !important;
    position: static;

    .col-w-100 {
      width: 100%;
      flex: 0 0 auto;
    }

    .category-details-container {
      margin-bottom: $font_size_5;
      padding: 0 0.125rem 0 0.125rem;

      .title-section {
        width: 7.5rem;
        height: auto;
        font-size: $font-size-24;
        font-weight: $font_weight_800;
        color: $white_bg;
        margin-right: $font_size_5;
      }

      .safety-content-section {
        flex: 1;
        height: 100%;

        .category-name {
          color: $white_bg;
          height: 40px;
          margin-bottom: 5px;
          margin-right: 0px;

          h3 {
            font-size: $font_size_18;
            font-weight: $font_weight_700;
            text-transform: $text-uppercase;
            margin-bottom: 0;
          }
        }

        .category-title-section {
          &.people-title-section {
            margin-bottom: $font_size_5;

            .items-section {
              // width: 50%;
              padding: $font_size_5;

              &.spacing-mr-20 {
                margin-right: $font_size_5;
              }
            }

            .comments-section {
              flex: 1;
              margin-right: 0.125rem;
              padding: $font_size_5;
              overflow: auto;
              //width: 80%;
              scrollbar-width: none;
              white-space: nowrap;
            }
          }

          &.cost-section {
            &.spacing-mb-20 {
              margin-bottom: 0px;
            }

            h3 {
              font-size: $font_size_14;
              font-weight: $font_weight_700;
              text-transform: capitalize;
              margin-bottom: 0;
            }
          }

          .items-section-delivery {
            height: 1.875rem;
            // padding: 0 0px 0 $font_size_40;
          }

          .delivery-section {
            margin-right: $font_size_5;

            &.delivery-sub-header-div {
              margin-top: 0.25rem;
            }

            &:last-child {
              margin-right: 0;
            }

            &.col-6.col-wd-4 {
              width: 33%;
            }

            &.col-wd-4 {
              flex: 1;
            }
          }

          .environment-sec {
            &.col-6 {
              &.col-wd-4 {
                width: 33%;
              }
            }

            &.col-wd-4 {
              flex: 1;
              margin-right: $font_size_5;
              padding: $font_size_5;

              &:last-child {
                margin-right: 0;
              }
            }
          }

          .items-section {
            color: $white_bg;
            height: 1.875rem;
            margin-right: $font_size_5;

            &.col-6.col-wd-4 {
              width: 33%;
            }
          }

          .value-section {
            color: $white_bg;
            height: 30px;
            flex: 1;
            margin-right: $font_size_5;
          }

          .comments-section {
            color: $white_bg;
            height: 1.875rem;
            flex: 1;
            overflow: auto;
            //width: 80%;
            scrollbar-width: none;
            white-space: nowrap;
          }

          h3 {
            font-size: $font_size_14;
            font-weight: $font_weight_700;
            text-transform: $text-uppercase;
            margin-bottom: 0;
          }

          &.environment-title-sec {
            &.spacing-mt-20 {
              margin-top: $font_size_5;
            }

            &.col-wd-2 {
              width: 33%;
              height: auto;
              margin-right: $font_size_5;
            }
          }
        }

        // Category details section
        .category-details-section {
          &.people-details-section {
            h3 {
              font-size: $font-size-16;
              font-weight: $font_weight_700;
            }

            p {
              font-size: $font-size-16;
              font-weight: $font_weight_400;
            }

            .people-items-section {
              // width: 50%;
              padding: $font_size_5;

              &.spacing-mr-20 {
                margin-right: $font_size_5;
                margin-bottom: $font_size_5;
              }
            }

            .people-comments-section {
              flex: 1;
              padding: $font_size_5;
              margin-right: 0.125rem;
              margin-bottom: $font_size_5;
            }
          }

          .env-details-section {
            width: 33%;
            height: auto;
            font-size: $font_size_14;
            color: $black_color;
            margin-right: $font_size_5;
            margin-bottom: $font_size_5;

            &.spacing-p-40 {
              padding: $font_size_5;
            }

            &:last-child {
              margin-right: 0.125rem;
            }

            h3 {
              font-size: $font_size_14;
            }
          }

          .defect-question-section {
            height: auto;
            margin: $font_size_5 0 0px 0px;
            padding: $font_size_5;

            &.col-wd-4 {
              width: 33%;
            }

            h4 {
              font-size: $font_size_14;
              font-weight: $font_weight_700;
            }

            p {
              font-size: $font_size_14;
              font-weight: $font_weight_400;
            }
          }

          &.environment-section {
            .defect-question-section {
              width: 33%;
            }

            .defect-answer-section {
              flex: 1;
              margin-right: 2px;

              // padding: 5px;
              .user-data-container {
                width: 49.5%;
                padding: 0;
              }

              .user-comments-container {
                flex: 1;
                padding: 0;

                .option-comments {
                  h3 {
                    padding-left: $font_size_5;
                  }
                }
              }
            }
          }

          .defect-answer-section {
            // width: calc(100% - 1300px);
            margin: $font_size_5 0 0px $font_size_5;
            // width: 64%;
            flex: 1;

            &.delivery-answer-sec {
              h3 {
                font-size: $font-size-16;
                margin-bottom: 0px;
              }

              .reports {
                background-color: gray;
                padding: 5px 10px 5px 10px;
                color: white;
                margin-top: 5px;
                margin-bottom: 5px !important;
              }
            }

            &.delivery-sub-sec {
              h3 {
                font-weight: $font_weight_700;
                font-size: 0.813rem;
              }
            }

            &.quality-defect-section {
              width: 66.3%;
            }

            &.safety-defect-answer-sec {
              .safety-value-section {
                &.spacing-mr-20 {
                  margin-right: $font_size_5;
                  padding-left: 0;
                }
              }

              h3,
              p {
                font-size: $font_size_16;
                padding-left: $font_size_5;
              }

              .reports {
                background-color: gray;
                padding: 5px 10px 5px 10px;
                color: white;
                margin-top: 5px;
                margin-bottom: 5px !important;
              }
            }

            .user-data-row {
              height: 100%;
            }

            .data-container {
              border: 1px solid $border-color;
              margin-bottom: $font_size_5;

              h3 {
                font-size: $font_size_14;
                text-transform: $text-uppercase;
                border-bottom: 2px solid $border-color;
                padding: $font_size_5;
                font-weight: $font_weight_400;
              }

              p {
                font-size: $font_size_14;
                padding: $font_size_5;
                font-weight: $font_weight_400;
              }

              &:last-child {
                margin-bottom: 0;
              }
            }

            .user-data-container {
              height: 100%;
              margin-right: $font_size_5;
              width: 50%;

              .option-data {
                h3 {
                  text-transform: $text-uppercase;
                  font-size: $font-size-16;
                  padding-left: $font_size_5;
                }
              }
            }

            .user-comments-container {
              height: 100%;
              flex: 1;

              .option-comments {
                width: 100%;

                h3 {
                  font-size: $font-size-16;
                  text-overflow: ellipsis;
                  overflow: hidden;
                  white-space: nowrap;
                  padding-left: $font_size_5;
                }
              }
            }

            &.no-report {
              width: 66.33%;
            }
          }

          .defect-status {
            width: 0.563rem;
            padding: 0;
            margin: $font_size_5 0 0 $font_size_5;
            height: auto;
            background-color: $black_color;
          }
        }
      }

      .cost-title-section {
        width: 12.5rem;
        height: auto;
        font-size: $font-size-16;
        font-weight: $font_weight_700;
        color: $black_color;
        margin-right: $font_size_5;
        margin-bottom: $font_size_5;
        padding: $font_size_5;

        // padding: 0 0 0 40px;
        &.col-5 {
          flex: 1;

          &:last-child {
            margin-right: 0.125rem;
            margin-bottom: $font_size_5;
          }
        }
      }

      .env-title-section {
        width: 33%;
        height: auto;
        font-weight: $font_weight_700;
        color: $black_color;
        margin-right: $font_size_5;
        margin-bottom: $font_size_5;
        padding: $font_size_5;

        &:last-child {
          margin-right: 0.125rem;
        }

        h3 {
          font-weight: $font_weight_700;
        }
      }
    }
  }
}

// Recap TV Screen
@include for-big-tv-screen {
  .recap-view-container {
    padding: 0.5rem;
    top: 12rem !important;
    position: absolute;
    margin-bottom: 3.5rem;
    margin-left: -0.5rem;
    margin-right: -0.5rem;
    width: 99.2%;

    app-position-row {
      display: inline;
    }

    .recap-header {
      zoom: 250%;
      // margin-top: 58px;
    }

    .area-header {
      border: 0.1rem solid $zero_report_bg_color;
      font-weight: $font_weight_700;
      font-size: 7rem;
      align-items: center;
    }

    .content-border {
      border: 0.1rem solid $zero_report_bg_color;

      .content-border-bottom {
        border-bottom: 0.1rem solid $zero_report_bg_color;
      }
    }

    .content-font {
      font-size: 64px;
    }

    .content-value {
      align-items: center;
      width: 11.25rem;
    }

    .content-button {
      width: 11.25rem;
      margin-left: 2rem !important;
    }

    .no-content-width-null {
      height: 80rem;
      align-items: center;
    }

    .content-width-null-text {
      display: flex;
      justify-content: center;
      color: $white_bg;
      background-color: $zero_report_bg_color;
      width: 1rem;
      height: 13rem;
      padding: 2rem 2rem;
      font-size: 7rem;
      line-height: 1;
    }

    .safety-colum-width {
      .count-col {
        flex: 34 !important;
      }

      .button-col {
        margin-left: 0px !important;
      }
    }

    .quality-colum-width {
      .count-col {
        flex: 34 !important;
      }

      .button-col {
        margin-left: 0px !important;
      }
    }

    .centerline-content-colum-width {
      flex: 0.96 !important;
      margin-left: 1px;
      margin-right: 4px;
    }

    .equpment-stops-colum-width {
      width: 1.16;
      overflow: hidden;
    }

    .safety-content-width {
      width: 79%;
      margin-right: 2rem !important;
    }

    .quality-content-width {
      width: 79%;
      margin-right: 2rem !important;
    }

    .quality-content-width-null {
      width: 84%;
      margin-right: 2rem !important;
    }

    .centerline-content-width {
      width: 79%;
      margin-right: 2rem !important;
    }

    .cid-content-width {
      // width: 5%;
      flex: 0.276;
      font-size: 6rem;
      line-height: 1.5;

      // width: 5%;
      height: 7.4rem;
      margin-right: 2px;
    }

    .equipment-stop-content-width {
      width: 83.17%;
      margin-right: 2rem !important;
    }

    .defect-equipment-content-width {
      width: 79%;
      margin-right: 2rem !important;
    }

    .button-press {
      background-color: $black_color;
      align-items: center;

      .bi-three-dots-vertical {
        color: $color-yellow;
      }
    }

    .button-unpress {
      background-color: $dark_gray;
      align-items: center;

      .bi-three-dots-vertical {
        color: $zero_report_bg_color;
      }
    }

    .safety-content {
      background-color: $color-light-red;
    }

    .safety-content {
      background-color: $color-light-red;
      flex: 100 !important;
      margin-right: 0px !important;
    }

    .quality-content {
      background-color: $color-light-skyblue;
    }

    .delivery-content {
      background-color: $color-light-yellow;
    }

    .box-model {
      width: 4rem;
      height: 4rem;
      background-color: $theme_color;

      &.box-model-shift {
        background-color: $color-green;
        align-items: center;
        color: $white_bg;
        margin: 1.5rem;
        padding: 1.5rem;
        width: 1.1rem;
        height: 1.1rem;
      }

      &.blue-arw {
        background-color: $light_Blue;
        color: $white_bg;
        width: 1.8rem;
        height: 1.9rem;
        padding: 0rem 0.4rem 0rem 0rem;
        margin-left: 1rem;

        img {
          width: 1rem;
        }
      }

      &.red-arw {
        background-color: $color-red;
        color: $white_bg;
        padding: 0rem 0.4rem 0rem 0rem;
        width: 1.8rem;
        height: 1.9rem;
        margin-left: 1rem;

        img {
          width: 1rem;
        }
      }
    }
  }

  .mx-1 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }

  .mr-2 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }

  .mr-3 {
    margin-right: 1.25rem !important;
    margin-left: 1rem !important;
  }

  .mt-2 {
    margin-top: 1rem !important;
    zoom: 250% !important;
  }

  img.arrow-img.high {
    transform: rotate(-90deg);
  }

  img.arrow-img.low {
    transform: rotate(90deg);
  }

  app-position-row {
    app-card-inner-content {
      .card-container {
        zoom: 209%;
        height: 37.5rem !important;

        .card-code {
          padding-top: 55px;
        }

        .content-border-bottom {
          height: 25%;
          line-height: 100px;
        }
      }
    }
  }
}

.filter-date-picker {
  .mat-mdc-text-field-wrapper {
    background: white !important;
    // font-family: ;
  }

  .mdc-text-field--no-label .mat-mdc-form-field-infix {
    padding-top: 10px !important;
  }

  .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover>.mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    background: white !important;
  }

  .mat-mdc-text-field-wrapper:focus {
    background: white !important;
    border-bottom: grey !important;
  }

  .mat-mdc-form-field-icon-suffix {
    margin-bottom: 10px !important;
  }

  .mdc-text-field--no-label:not(.mdc-text-field--textarea) .mat-mdc-form-field-input-control.mdc-text-field__input,
  .mat-mdc-text-field-wrapper .mat-mdc-form-field-input-control {
    font-family: "Lato-Regular" !important;
    font-size: 10pt !important;
  }
}

.top-cid-sec {
  .multiselect-dropdown {
    border: 2px solid black !important;
    margin-left: 13px !important;
  }
}

.current-preview-modal {
  margin-left: 263px;

  app-route-details-preview .modal-validation-container {
    background-color: $white_bg;
    width: 875px;
    height: 565px;
    gap: 0px;
    border: 1px 0px 0px 0px;
    opacity: 0px;
  }

  app-route-details-preview .modal {
    background: rgba(0, 0, 0, 0.3);
  }
}

html,
body {
  height: 100%;
}

body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

html,
body {
  height: 100%;
}

body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.defect-repoted-table-flex {
  display: flex;

  .table-defect-flag {
    width: 10px;
    width: 48px;
    height: 47px;
    background-color: white !important;
    border: 0.01px solid $border-color;
    pointer-events: unset !important;
    border-left: none;
    border-top: none;
    margin-left: 5px;
    margin-top: 67px;
    background-image: url(assets/images/Flag.svg);
  }

  .table-defect-flag-selected {
    width: 10px;
    width: 48px;
    height: 47px;
    background-color: white !important;
    border: 0.01px solid $border-color;
    pointer-events: unset !important;
    border-left: none;
    border-top: none;
    margin-left: 5px;
    margin-top: 67px;
    background-image: url(assets/images/Flag-Selected.svg);
  }
}

.defect-repoted-table {
  padding-bottom: 20px;
  width: 97%;
  gap: 2px;

  .table-defect-global {
    display: flex;

    align-items: center;
    border: 1px solid #828288;
    padding: 7px;

    .defect-head {
      width: 21rem;
      min-width: 20%;
      color: #828288;
    }

    .defect-value {
      width: 45%;
      min-width: 20%;
    }

    .defect-date {
      width: 16%;
      float: right;
      background-color: $black_color;
      color: $theme_color;
      margin-left: 2rem;
    }
  }
}

@include for-small-screen {
  .kpi-view-container {
    min-width: 68.75rem;
    overflow-x: scroll;
    margin-top: 3.4rem !important;
    position: static;

    .col-w-100 {
      width: 100%;
      flex: 0 0 auto;
    }

    .category-details-container {
      margin-bottom: $font_size_5;
      padding: 0 0.125rem 0 0.125rem;

      .title-section {
        width: 7.5rem;
        height: auto;
        font-size: $font-size-24;
        font-weight: $font_weight_800;
        color: $white_bg;
        margin-right: $font_size_5;
      }

      .safety-content-section {
        flex: 1;
        height: 100%;

        .category-name {
          color: $white_bg;
          height: 40px;
          margin-bottom: 5px;
          margin-right: 0px;

          h3 {
            font-size: $font_size_18;
            font-weight: $font_weight_700;
            text-transform: $text-uppercase;
            margin-bottom: 0;
          }
        }

        .category-title-section {
          &.people-title-section {
            margin-bottom: $font_size_5;

            .items-section {
              // width: 50%;
              padding: $font_size_5;

              &.spacing-mr-20 {
                margin-right: $font_size_5;
              }
            }

            .comments-section {
              flex: 1;
              margin-right: 0.125rem;
              padding: $font_size_5;
              overflow: auto;
              //width: 80%;
              scrollbar-width: none;
              white-space: nowrap;
            }
          }

          &.cost-section {
            &.spacing-mb-20 {
              margin-bottom: 0px;
            }

            h3 {
              font-size: $font_size_14;
              font-weight: $font_weight_700;
              text-transform: capitalize;
              margin-bottom: 0;
            }
          }

          .items-section-delivery {
            height: 1.875rem;
            // padding: 0 0px 0 $font_size_40;
          }

          .delivery-section {
            margin-right: $font_size_5;

            &:last-child {
              margin-right: 0;
            }

            &.col-6.col-wd-4 {
              width: 33%;
            }

            &.col-wd-4 {
              flex: 1;
            }
          }

          .environment-sec {
            &.col-6 {
              &.col-wd-4 {
                width: 33%;
              }
            }

            &.col-wd-4 {
              flex: 1;
              margin-right: $font_size_5;
              padding: $font_size_5;

              &:last-child {
                margin-right: 0;
              }
            }
          }

          .items-section {
            color: $white_bg;
            height: 1.875rem;
            margin-right: $font_size_5;

            &.col-6.col-wd-4 {
              width: 33%;
            }
          }

          .value-section {
            color: $white_bg;
            height: 30px;
            flex: 1;
            margin-right: $font_size_5;
          }

          .comments-section {
            color: $white_bg;
            height: 1.875rem;
            flex: 1;
            overflow: auto;
            //width: 80%;
            scrollbar-width: none;
            white-space: nowrap;
          }

          h3 {
            font-size: $font_size_14;
            font-weight: $font_weight_700;
            text-transform: $text-uppercase;
            margin-bottom: 0;
          }

          &.environment-title-sec {
            &.spacing-mt-20 {
              margin-top: $font_size_5;
            }

            &.col-wd-2 {
              width: 33%;
              height: auto;
              margin-right: $font_size_5;
            }
          }
        }

        // Category details section
        .category-details-section {
          &.people-details-section {
            h3 {
              font-size: $font-size-16;
              font-weight: $font_weight_700;
            }

            p {
              font-size: $font-size-16;
              font-weight: $font_weight_400;
            }

            .people-items-section {
              // width: 50%;
              padding: $font_size_5;

              &.spacing-mr-20 {
                margin-right: $font_size_5;
                margin-bottom: $font_size_5;
              }
            }

            .people-comments-section {
              flex: 1;
              padding: $font_size_5;
              margin-right: 0.125rem;
              margin-bottom: $font_size_5;
            }
          }

          .env-details-section {
            width: 33%;
            height: auto;
            font-size: $font_size_14;
            color: $black_color;
            margin-right: $font_size_5;
            margin-bottom: $font_size_5;

            &.spacing-p-40 {
              padding: $font_size_5;
            }

            &:last-child {
              margin-right: 0.125rem;
            }

            h3 {
              font-size: $font_size_14;
            }
          }

          .defect-question-section {
            height: auto;
            margin: $font_size_5 0 0px 0px;
            padding: $font_size_5;

            &.col-wd-4 {
              width: 33%;
            }

            h4 {
              font-size: $font_size_14;
              font-weight: $font_weight_700;
            }

            p {
              font-size: $font_size_14;
              font-weight: $font_weight_400;
            }
          }

          &.environment-section {
            .defect-question-section {
              width: 33%;
            }

            .defect-answer-section {
              flex: 1;
              margin-right: 2px;

              // padding: 5px;
              .user-data-container {
                width: 49.5%;
                padding: 0;
              }

              .user-comments-container {
                flex: 1;
                padding: 0;

                .option-comments {
                  h3 {
                    padding-left: $font_size_5;
                  }
                }
              }
            }
          }

          .defect-answer-section {
            // width: calc(100% - 1300px);
            margin: $font_size_5 0 0px $font_size_5;
            // width: 64%;
            flex: 1;

            &.delivery-answer-sec {
              h3 {
                font-size: $font-size-16;
              }

              .reports {
                background-color: gray;
                padding: 5px 10px 5px 10px;
                color: white;
                margin-top: 5px;
                margin-bottom: 5px !important;
              }
            }

            &.delivery-sub-sec {
              h3 {
                font-weight: $font_weight_700;
                font-size: 0.813rem;
              }
            }

            &.quality-defect-section {
              width: 66.3%;
            }

            &.safety-defect-answer-sec {
              .safety-value-section {
                &.spacing-mr-20 {
                  margin-right: $font_size_5;
                  padding-left: 0;
                }
              }

              h3,
              p {
                font-size: $font_size_16;
                padding-left: $font_size_5;
              }

              .reports {
                background-color: gray;
                padding: 5px 10px 5px 10px;
                color: white;
                margin-top: 5px;
                margin-bottom: 5px !important;
              }
            }

            .user-data-row {
              height: 100%;
            }

            .data-container {
              border: 1px solid $border-color;
              margin-bottom: $font_size_5;

              h3 {
                font-size: $font_size_14;
                text-transform: $text-uppercase;
                border-bottom: 2px solid $border-color;
                padding: $font_size_5;
                font-weight: $font_weight_400;
              }

              p {
                font-size: $font_size_14;
                padding: $font_size_5;
                font-weight: $font_weight_400;
              }

              &:last-child {
                margin-bottom: 0;
              }
            }

            .user-data-container {
              height: 100%;
              margin-right: $font_size_5;
              width: 50%;

              .option-data {
                h3 {
                  text-transform: $text-uppercase;
                  font-size: $font-size-16;
                  padding-left: $font_size_5;
                }
              }
            }

            .user-comments-container {
              height: 100%;
              flex: 1;

              .option-comments {
                width: 100%;

                h3 {
                  font-size: $font-size-16;
                  text-overflow: ellipsis;
                  overflow: hidden;
                  white-space: nowrap;
                  padding-left: $font_size_5;
                }
              }
            }

            &.no-report {
              width: 66.33%;
            }
          }

          .defect-status {
            width: 0.563rem;
            padding: 0;
            margin: $font_size_5 0 0 $font_size_5;
            height: auto;
            background-color: $black_color;
          }
        }
      }

      .cost-title-section {
        width: 12.5rem;
        height: auto;
        font-size: $font-size-16;
        font-weight: $font_weight_700;
        color: $black_color;
        margin-right: $font_size_5;
        margin-bottom: $font_size_5;
        padding: $font_size_5;

        // padding: 0 0 0 40px;
        &.col-5 {
          flex: 1;

          &:last-child {
            margin-right: 0.125rem;
            margin-bottom: $font_size_5;
          }
        }
      }

      .env-title-section {
        width: 33%;
        height: auto;
        font-weight: $font_weight_700;
        color: $black_color;
        margin-right: $font_size_5;
        margin-bottom: $font_size_5;
        padding: $font_size_5;

        &:last-child {
          margin-right: 0.125rem;
        }

        h3 {
          font-weight: $font_weight_700;
        }
      }
    }
  }
}

.full-screen-modal {
  .modal-content {
    height: 100%;
    width: 100%;
    position: fixed;
    left: 0px;
    top: 0px;
    background-color: rgba(253, 253, 253, 0.77);
  }
}

.full-screen-modal-current {
  .modal-content {
    height: 100%;
    width: 100%;
    position: fixed;
    left: 0px;
    top: 0px;
    background-color: white;
  }
}

.filter-date-picker {
  .mat-mdc-text-field-wrapper {
    background: white !important;
    // font-family: ;
  }

  .mdc-text-field--no-label .mat-mdc-form-field-infix {
    padding-top: 10px !important;
  }

  .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover>.mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    background: white !important;
  }

  .mat-mdc-text-field-wrapper:focus {
    background: white !important;
    border-bottom: grey !important;
  }

  .mat-mdc-form-field-icon-suffix {
    margin-bottom: 10px !important;
  }

  .mdc-text-field--no-label:not(.mdc-text-field--textarea) .mat-mdc-form-field-input-control.mdc-text-field__input,
  .mat-mdc-text-field-wrapper .mat-mdc-form-field-input-control {
    font-family: "Lato-Regular" !important;
    font-size: 10pt !important;
  }
}

.top-cid-sec {
  .multiselect-dropdown {
    border: 2px solid black !important;
    margin-left: 13px !important;
  }
}

.current-preview-modal {
  margin-left: 263px;

  app-route-details-preview .modal-validation-container {
    background-color: $white_bg;
    width: 875px;
    height: 565px;
    gap: 0px;
    border: 1px 0px 0px 0px;
    opacity: 0px;
  }

  app-route-details-preview .modal {
    background: rgba(0, 0, 0, 0.3);
  }
}

html,
body {
  height: 100%;
}

body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

html,
body {
  height: 100%;
}

body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.defect-repoted-table-flex {
  display: flex;

  .table-defect-flag {
    width: 10px;
    width: 48px;
    height: 47px;
    background-color: white !important;
    border: 0.01px solid $border-color;
    pointer-events: unset !important;
    border-left: none;
    border-top: none;
    margin-left: 5px;
    margin-top: 67px;
    background-image: url(assets/images/Flag.svg);
  }

  .table-defect-flag-selected {
    width: 10px;
    width: 48px;
    height: 47px;
    background-color: white !important;
    border: 0.01px solid $border-color;
    pointer-events: unset !important;
    border-left: none;
    border-top: none;
    margin-left: 5px;
    margin-top: 67px;
    background-image: url(assets/images/Flag-Selected.svg);
  }
}

.defect-repoted-table {
  padding-bottom: 20px;
  width: 97%;
  gap: 2px;

  .table-defect-global {
    display: flex;

    align-items: center;
    border: 1px solid #828288;
    padding: 7px;

    .defect-head {
      width: 21rem;
      min-width: 20%;
      color: #828288;
    }

    .defect-value {
      width: 45%;
      min-width: 20%;
    }

    .defect-date {
      width: 16%;
      float: right;
      background-color: $black_color;
      color: $theme_color;
      margin-left: 2rem;
    }
  }
}

.photo-upload-container .route-completion .mdc-checkbox__background {
  width: 100% !important;
  height: 100% !important;
  margin-top: -0.8rem;
  border-color: $dark_blue !important;
}


.MAT.SPDT {
  .pb-3 {
    margin-top: 30px !important;
  }
}

.owl-dt-trigger-disabled {
  opacity: 1;
  border: 1px solid $box_border;
}

.summary-container {
  .multiselect-dropdown .dropdown-btn .selected-item-container .selected-item span {
    word-break: break-all !important;
  }
}

owl-date-time-container * {
  text-transform: capitalize;
}