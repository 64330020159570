@import "../../assets/styles/variables";
.multiselect-dropdown {
  position: relative;
  width: 100%;
  font-size: inherit;
  font-family: inherit;
  .dropdown-btn {
    display: inline-block;
    border: 1px solid $box_border;
    width: 100%;
    padding: 6px 12px;
    margin-bottom: 0;
    min-height: 2.8rem ;
    max-height: 100px;
    overflow: auto;
    font-weight: normal;
    line-height: 30px !important;
    text-align: left;
    vertical-align: middle;
    cursor: pointer;
    background-image: none;
    border-radius: 0 !important;
    .selected-item {
      border: 1px solid $base_color !important;
      margin-right: 4px;
      background: $base_color !important;
      padding: 0px 5px;
      color:  $black_color !important;
      border-radius: 2px;
      float: left;
      a {
        text-decoration: none;
        color: $black_color !important;
        font-size: $font-size-24;
      }
    }
    .selected-item:hover {
      box-shadow: none !important;
    }
    .dropdown-down {
      display: inline-block;
      top: 10px;
      width: 0;
      height: 0;
      border-top: 10px solid $box_border;
      border-left: 10px solid transparent;
      border-right: 10px solid transparent;
    }
    .dropdown-up {
      display: inline-block;
      width: 0;
      height: 0;
      border-bottom: 10px solid $box_border;
      border-left: 10px solid transparent;
      border-right: 10px solid transparent;
    }
  }
  .disabled {
    & > span {
      background-color: $black_color;
    }
  }
}

.dropdown-list {
  position: absolute;
  padding-top: 0px !important;
  width: 100%;
  z-index: 9999;
  border: 1px solid #ccc;
  border-radius: 3px;
  background: #fff;
  margin-top: 1px !important;
  box-shadow: 0px 1px 5px #959595;
  ul {
    padding: 0px;
    list-style: none;
    overflow: auto;
    margin: 0px;
    position: relative;
  }
  li {
    padding: 6px 10px;
    cursor: pointer;
    text-align: left;
  }
  .filter-textbox {
    border-bottom: 1px solid #ccc;
    position: relative;
    padding: 10px;
    input {
      border: 0px;
      width: 100%;
      padding: 0px 0px 0px 26px;
    }
    input:focus {
      outline: none;
    }
  }
  li:not(.filter-textbox):hover{
    background-color:$color-light-blue !important;
  }
}

.multiselect-item-checkbox input[type='checkbox'] {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

.multiselect-item-checkbox input[type='checkbox']:focus + div:before,
.multiselect-item-checkbox input[type='checkbox']:hover + div:before {
  display: none;
}

.multiselect-item-checkbox input[type='checkbox']:active + div:before {
  transition-duration: 0s;
}

.multiselect-item-checkbox input[type='checkbox'] + div {
  position: relative;
  padding-left: 0 !important;
  vertical-align: middle;
  user-select: none;
  cursor: pointer;
  margin: 0px;
  color: $black_color;
}

.multiselect-item-checkbox input[type='checkbox'] + div:before {
  display: none;
}

.multiselect-item-checkbox input[type='checkbox'] + div:after {
  display: none;
}


.multiselect-item-checkbox input[type='checkbox'] + div:after {
  display: none;
}
.multiselect-item-checkbox:has( input[type='checkbox']:checked){
 background-color: $color-light-blue;
}
.multiselect-item-checkbox input[type='checkbox']:checked + div:after {
  content: '';
  transform: rotate(-45deg) scale(1);
  transition: transform 200ms ease-out;
}

.multiselect-item-checkbox input[type='checkbox']:checked + div:before {
  animation: borderscale 200ms ease-in;
  background: $base_color;
}

.multiselect-item-checkbox input[type='checkbox']:checked + div:after {
  transform: rotate(-45deg) scale(1);
}
.multiselect-dropdown .dropdown-btn .selected-item{
  background-color: transparent !important;
  border:0 !important;
  
  a{
    display: none;
  }
}
@keyframes borderscale {
  50% {
    box-shadow: 0 0 0 2px $base_color;
  }
}

.multiselect-dropdown {
    .selected-item {
      max-width: 100% !important;
      margin-bottom: 5px;
      margin-right: 5px !important;
      height: 24px;
      line-height: 17px;
      position: relative;
      top: 3px;
    }
}

.multiselect-dropdown .dropdown-btn .dropdown-multiselect__caret::before {
  border: none !important;
  background-image:url('../images/chevron-down.svg');
  background-size: 40px 25px;
  display: inline-block;
  width: 35px;
  height: 40px;
  content: "" !important;
  top: 0 !important;
  background-repeat: no-repeat;
  right: 0.3rem !important;

}

.shift-select-drpdwn {
  .multiselect-dropdown .dropdown-btn .dropdown-multiselect__caret::before {
    border: none !important;
    background-image:url('../images/chevron-down.svg');
    background-size: 40px 25px;
    display: inline-block;
    width: 35px;
    height: 40px;
    content: "" !important;
    top: 0 !important;
    background-repeat: no-repeat;
    right: 0.3rem !important;
  
  }
  
}

.filter-container {
  .multiselect-dropdown .dropdown-btn .selected-item{
    background-color: $color-light-blue !important;
    border:0 !important;
    align-items: baseline;
    display: flex;
    
    a{
      display: block;
    }
  }
}