@import "./variables";

@mixin header_class {
  background-color: $headercolor;
  height: 40px;
  top: -100px !important;
}

@mixin footer_class {
  position: fixed;
  bottom: 0;
  left: 0;
  background-color: $black_color;
  width: 100% !important;
  height: 50px;
}

@mixin table_header {
  background-color: black;
  color: $theme_color;
  font-size: $font_size_16;
  font-style: normal;
  font-weight: $font_weight_bold;
}

@mixin plant_line_code($color) {
  background-color: $color;
  color: white;
  width: 3.75rem;
  height: 1.563rem;
  line-height: 1.563rem;
  display: inline-block;
  text-align: center;

}


@mixin hand_off_sub_headers($color, $bg_color) {
  border: 1px solid $color;
  background: $bg_color;
  display: flex;
  padding: 10px;
  align-items: center;
  gap: 10px;
}

@mixin custom-radio-button {
  display: flex;
  flex-direction: row;


  input[type="radio"] {
    position: absolute;
    left: -9999px;
  }

  input[type="radio"]+label {
    position: relative;
    padding: 3px 0 0 40px;
    cursor: pointer;
  }

  input[type="radio"]+label:before {
    content: '';
    background: $white-bg;
    border: 1px solid $light_Blue;
    height: 1.3rem;
    width: 1.3rem;
    border-radius: 50%;
    position: absolute;
    top: 0.2rem;
    left: 0.2rem;
  }

  input[type="radio"]+label:after {
    content: '';
    background: $light_Blue;
    width: 0.8rem;
    height: 0.8rem;
    border-radius: 50%;
    position: absolute;
    top: 0.45rem;
    left: 0.45rem;
    opacity: 0;
    transform: scale(2);
    transition: transform 0.3s linear, opacity 0.3s linear;
  }

  input[type="radio"]:checked+label:after {
    opacity: 1;
    transform: scale(1);
  }
}

@mixin textbox-content {

  input[type="text"],
  input[type="number"],
  input[type="date"],
  label {
    border: 1px solid $box_border !important;
    border-radius: 0;
  }

  input[type="text"]:focus,
  input[type="number"]:focus,
  input[type="date"]:focus,
  label:focus {
    box-shadow: none !important;
    border-color: $black-color !important;

  }
}

@mixin zero-report-wrapper {
  background-color: $content-bg;
  margin: 1rem 0;
  align-items: center;
  font-weight: $font_weight_400;
  font-size: 1rem;

  .safety-report-zero {
    display: grid;
    align-items: center;
    justify-content: center;
    background-color: $zero_report_bg_color;
    width: 3rem;
    height: 3rem;
    color: white;
    margin-right: 1rem;
    transform: rotate(90deg) !important;

    .icon-size {
      font-size: 1.75rem;
    }
  }
}

// Huddle board Header Mixin
@mixin huddle-board-header-text {
  font-size: 6.25rem;
  font-weight: $font_weight_700;
  color: $black_color;
}

@mixin header-text-desktop-view {
  font-size: $font_size_14;
  font-weight: $font_weight_700;
  color: $black_color;
}

// Media Queries Mixin
@mixin for-big-tv-screen {
  @media screen and (min-width: 3001px) and (max-width: 9999px){
    @content;
  }
}

@mixin for-mobile-to-desktop-screen {
  @media screen and (min-width: 328px) and (max-width: 3000px) {
    @content;
  }
}

@mixin for-small-screen {
  @media screen and (min-width: 160px) and (max-width: 320px) {
    @content;
  }
}

@mixin for-desktop-screen {
  @media screen and (min-width: 328px) and (max-width: 2000px) {
    @content;
  }
}
@mixin textbox-wrapper {
  position: relative;


  span {
    position: absolute;
    right: 5%;
    top: 16%;
    color: $black_color !important;
    background-color: $content-bg !important;
    font-weight: 100;
    padding: 0.375rem;
    font-size: 0.875rem;
    margin-right: 0;
  }

  .hours-input-padding {
    padding-right: 5.5rem;
  }
}